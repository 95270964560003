/*-----------------------------------------------------------
    Theme Name: Doob | Simple Agency HTML Template
    Description: Simple Agency HTML Template
    Author: Graphberry
    Author URI: https://www.graphberry.com/
    Version: 1.0
-------------------------------------------------------------*/


/*-----------------------------------------------------------
    1  General Styles
    2  Typography
    3  Buttons & Links 
    4  Header
    5  Services
    6  About  
    7  Portfolio
    8  Blog
    9  Contact
    10 Footer
    11 Responsive 
-------------------------------------------------------------*/


/*-----------------------------------------------------------
    1. General Styles 
-------------------------------------------------------------*/
* {
  margin: 0;
  padding: 0;
}
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
a {
  font-weight: 600;
  color: #314584;
  text-decoration: none;
  letter-spacing: 1px;
  position: relative;
}
a:focus,
a:hover {
  color: rgba(44, 44, 44, 0.8);
  text-decoration: none;
}
img {
  max-width: 100%;
  height: auto;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: #314584 !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
}
:active,
:focus {
  outline: none !important;
}
/*-----------------------------------------------------------
  2. Typography 
------------------------------------------------------------*/
body {
  color: #314584;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  overflow-x: hidden;
  transition: opacity 1s;
  min-width: 330px;
}
h1,
.h1 {
  font-size: 4.4rem !important;
  margin-bottom: 20px !important;
}
h2,
.h2 {
  font-size: 2.5rem !important;
  line-height: 3.5rem !important;
  margin-bottom: 50px !important;
}
.about h2 {
  margin-bottom: 25px;
}
h3,
.h3 {
  font-size: 1.9rem !important;
  line-height: 2.5rem !important;
  margin-bottom: 15px !important;
}
h4,
.h4 {
  font-size: 24px !important;
}

h5,
.h5 {
  font-size: 1rem !important;
  color: #bb82fd !important;
}
h6,
.h6 {
  font-size: 1rem !important;
  margin-bottom: 10px !important;
}
.about p {
  color: #314584;
}
p {
  font-size: 1.1rem !important;
  line-height: 1.8 !important;
  color: #6E757A !important;
  margin-bottom: 15px !important;
  letter-spacing: 0.025em !important;
}
/*-----------------------------------------------------------
  3. Buttons & Links
-------------------------------------------------------------*/
.contact-btn button {
  width: 200px;
  height: 60px;
  border: 0px;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(30,32,33,0.07);
  -moz-box-shadow: 0px 4px 6px 0px rgba(30,32,33,0.07);
  box-shadow: 0px 4px 6px 0px rgba(30,32,33,0.07);
  background-color: #fff;
  border-radius: 3px;
  font-size: 1.2rem;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  color: #bb82fd;
}
.contact-btn i {
  font-size: 2rem;
  width: 100px;
  height: 100px;
  color: #bb82fd;
}
.hero-btns a:first-child {
  background-color: #bb82fd;
  border:2px solid #bb82fd;
  color: #fff;
  padding: 17px 35px;
  font-size: 1.0rem;
  border-radius: 30px;
  -webkit-box-shadow: 0px 4px 5px 0px #bb8ffd;
  -moz-box-shadow: 0px 4px 5px 0px #bb8ffd;
  box-shadow: 0px 4px 5px 0px #bb8ffd;
}
.hero-btns a:first-child:hover {
  background-color: #fff;
  border:2px solid #bb82fd;
  color: #314584;
  padding: 17px 35px;
  font-size: 1.0rem;
  border-radius: 30px;
  transition: all 0.3s ease;
}
.hero-btns a:last-child {
  color: #314584;
  padding: 17px 35px;
  font-size: 1.0rem;
}
.hero-btns a:last-child:hover {
  color: #bb82fd;
  transition: all 0.3s ease;
}
.navbar-light .navbar-toggler {
  color: #314584;
  border: none;
}
.navbar-toggler {
  padding: .5rem 1.2rem .25rem 0rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem;
}
.interactive-menu-button {
  position: relative;
}
.interactive-menu-button a {
  width: 20px;
  height: 20px;
  display: block;
  position: relative;
}
.interactive-menu-button a span {
  height: 4px;
  position: absolute;
  background: #314584;
  text-indent: -999em;
  width: 100%;
  top: 6px;
  transition: all 0.5s cubic-bezier(0, 0.275, 0.125, 1);
}
.interactive-menu-button a span:before,
.interactive-menu-button a span:after {
  content: '';
  height: 4px;
  background: #314584;
  width: 100%;
  position: absolute;
  top: -6px;
  left: 0;
  transition: all 0.5s cubic-bezier(0, 0.275, 0.125, 1);
}
.interactive-menu-button a span:after {
  top: 6px;
}
.interactive-menu-button a.active span {
  background: transparent;
}
.interactive-menu-button a.active span:before {
  transform: translateY(6px) translateX(1px) rotate(45deg);
}
.interactive-menu-button a.active span:after {
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
}
.navbar-nav .dropdown-menu {
  text-align: center;
  border: 0;
  background-color: #314584;
}
.navbar-nav .dropdown-menu a {
  color: #fff;
  cursor: pointer;
  font-size: 1.3rem;
  margin: 10px 0px;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: #bb82fd!important;
  text-decoration: none;
  background-color: #314584;
}
.service-btn a {
  color: #fff!important;
  font-size: 0.8rem!important;
}
.service-btn a:hover {
  color: #bb82fd!important;
}
.more-btn {
  text-align: center;
  margin: 15px 0px 100px 0px;
}
.more-btn a {
  font-size: 1.2rem;
  color: #bb82fd!important;
  cursor: pointer;
  padding: 20px 30px;
}
.more-btn a:hover {
  color: #314584!important;
}
.contact-btn {
  text-align: center;
  margin-top: 70px;
}
.contact-btn a {
  color: #fff!important;
}
.contact-btn a:hover {
  color: #314584!important;
}
/*-----------------------------------------------------------
  4. Header
-------------------------------------------------------------*/
.navbar-light .navbar-nav .nav-link {
  color: #314584;
  font-size: 1.1rem;
}
.hero-btns {
  margin-bottom: 350px;
}
.hero-btns a {
  text-decoration: none !important;
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
  color: #bb82fd;
}
.navbar {
  padding: 50px 0 0 0;
}
.nav-item {
  padding: 0 10px;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #bb82fd;
}
.hero img {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  z-index: -9 !important;
  margin-top: 30px !important;
  width: 50% !important;
}
.hero h1 {
  margin-top: 120px !important;
}
.hero p {
  margin-bottom: 50px !important;
}
/*-----------------------------------------------------------
  5. Services
-------------------------------------------------------------*/
.services {
  padding: 50px 0px;
}
.services p {
  font-size: 1.0rem;
  color: #314584;
  line-height: 1.5rem;
}
.side-img img {
  position: absolute;
  left: -20px;
}
.side2-img img {
  position: absolute;
  right: -20px;
  top: 1300px;
}
.service-box {
  background-color: #fff;
  padding: 40px 45px;
  margin: 10px;
  -webkit-box-shadow: 0px 3px 7px 0px rgba(28,31,33,0.15);
  -moz-box-shadow: 0px 3px 7px 0px rgba(28,31,33,0.15);
  box-shadow: 0px 3px 7px 0px rgba(28,31,33,0.15);
  min-height: 400px;
}
.service-box img {
  margin-bottom: 30px;
}
/*-----------------------------------------------------------
  6. About
-------------------------------------------------------------*/
.about {
  margin-bottom: 150px;
}
/*-----------------------------------------------------------
 7. Portfolio
-------------------------------------------------------------*/
.portfolio-aside img {
  margin-top: 20px;
  position: absolute;
  left: -20px;
}
.work-box {
  margin-bottom: 30px;
}
.work-box img {
  width: 370px;
  height: auto;
}
/* Portfolio Gallery Hover */
.photobox{
  display: inline-block;
}
.photobox__previewbox{
  position: relative;
  overflow: hidden;
}
.photobox__preview{
  display: block;
  max-width: 100%;
}
.photobox__previewbox:before{
  content: "";
}
.photobox_type10 .photobox__previewbox:before{
  width: 0;
  height: 0;
  padding: 25%;
  background-color: #314584;
  opacity: 0.8;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform calc(var(--photoboxAnimationDuration, .8s) / 2) ease;
  will-change: transform;
  transform: translate(-50%, -50%) scale(0);
}
.photobox_type10:hover .photobox__previewbox:before{
  transform: translate(-50%, -50%) scale(4);
  transition-duration: var(--photoboxAnimationDuration, .8s);
}
.photobox_type10 .photobox__label{
  width: 95%;
  text-align: center;
  opacity: 0;  
  transition: opacity calc(var(--photoboxAnimationDuration, .8s) / 4) cubic-bezier(0.71, 0.05, 0.29, 0.9), transform calc(var(--photoboxAnimationDuration, .8s) / 4) cubic-bezier(0.71, 0.05, 0.29, 0.9);
  will-change: opacity, transform;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 200%);
  z-index: 3;
  color: #fff;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  font-size: 1.1rem; 
}
.photobox_type10:hover .photobox__label{
  opacity: 1;
  transform: translate(-50%, -50%);
  transition-duration: calc(var(--photoboxAnimationDuration, .8s) / 2);
  transition-delay: calc(var(--photoboxAnimationDuration, .8s) / 4);
}
.hide {
  display: none;
  transition: all 0.3s ease;
}
/*-----------------------------------------------------------
  8. Blog
-------------------------------------------------------------*/
.blog-aside img {
  position: absolute;
  right: -20px;
  margin-top: 200px;
}
.blog {
  padding: 15px 0px !important;
  margin-bottom: 10px !important;
}
.blog-box {
  background-color: #fff;
  min-height: 330px;
  max-width: 370px;
  padding: 50px 25px;
  -webkit-box-shadow: 0px 3px 7px 0px rgba(28,31,33,0.15);
  -moz-box-shadow: 0px 3px 7px 0px rgba(28,31,33,0.15);
  box-shadow: 0px 3px 7px 0px rgba(28,31,33,0.15);
  margin: 10px;
}
.blog-box p:nth-child(2) {
  font-size: 0.9rem;
  color: #6E757A!important;
}
.blog-box p:nth-child(3) {
  font-size: 1.2rem;
  color: #314584;
}
.slick-prev {
  position: absolute;
  right: 60px;
  top: -50px;
}
.slick-next  {
  position: absolute;
  right: 10px;
  top: -50px;
}
.blog-slider button {
  font-size: 3rem;
  border: none;
  background-color: #fff;
  color: #BBBBBC;
}
.blog-slider button:hover {
  color: #314584;
  transition: all 0.3s ease;
}
.blog-first {
  background-color: #bb82fd;
}
.blog-first h6 {
  color: #fff;
}
.blog-first p:nth-child(2) {
  color: #fff!important;
}
.blog-first p:nth-child(3) {
  color: #fff;
}
.blog-slider {
  cursor: pointer;
}
.hide-blog {
  display: none;
}
#blog-btn {
  color: #bb82fd;
  font-size: 1.2rem;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  background-color: #fff;
  border: none;
  margin-top: 20px;
}
#blog-btn:hover {
  color: #314584;
}
.hide-me {
  display: none;
}
/*-----------------------------------------------------------
  9. Contact
-------------------------------------------------------------*/
.contact {
  max-height: 550px;
  
}
.contact h2 {
  margin-bottom: 5px !important;
}
.email input {
  width: 100%;
  height: 60px;
  background-color: #F4FAFE;
  border: none;
  padding: 0px 20px;
  color: #314584;
  font-weight: 600;
  margin-bottom: 15px;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #314584;
  font-weight: 600;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #314584;
  font-weight: 600;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #314584;
  font-weight: 600;
}
:-moz-placeholder { /* Firefox 18- */
  color: #314584;
  font-weight: 600;
}
textarea {
  background-color: #F4FAFE;
  border: none;
  width: 100%;
  padding: 20px;
  margin-top: 15px;
  color: #314584;
  font-weight: 600;
}
/*-----------------------------------------------------------
  10. Footer
-------------------------------------------------------------*/
footer {
  background-color: #161515;
  width: 100%;
  min-height: 400px;
  text-align: center;
  padding: 70px 0px 0px 0px;
}
footer h5{
  color: #fff;
  padding-bottom: 10px;
}
footer h3{
  color: #bb82fd !important;
  font-size: 3rem !important;
  margin-bottom: 30px !important;
}
footer a {
  text-decoration: none !important;
}
.contact-nav li {
  display: inline;
  color: #fff;
}
.contact-nav a {
  color: #fff;
  font-size: 1.1rem;
  padding: 20px 20px;
}
.contact-nav a:hover {
  color: #bb82fd;
}
footer h6 {
  color: #fff !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  margin-top: 50px !important;
  margin-bottom: 20px !important;
}
.social li {
  display: inline;
}
.social li a {
  font-size: 2.7rem;
  color: #fff;
  margin: 0px 30px;
}
.social li a:hover {
  color: #bb82fd;
  transition: all 0.3s ease;
}
/*-----------------------------------------------------------
  11. Responsive
-------------------------------------------------------------*/
@media (max-width: 1700px) {
  .hero-btns {
    margin-bottom: 200px;
  }
}
@media (max-width: 1500px) {
  .hero img {
    margin-top: 130px !important;
  }
}
@media (max-width: 1350px) {
  .hero-btns {
    margin-bottom: 140px;
  }
}
@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1140px !important;
  }
}
@media (max-width: 1199px) {
  .nav-item {
    padding: 0px; 
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 1rem;
  }
  .contact-btn button {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow:none;
    background-color: none;
    font-size: 1rem;
}
  .hero img {
    margin-top: 150px !important;
    width: 45% !important;
  }
  .hero h1 {
    margin-top: 120px !important;
  }
  h2 {
    font-size: 2rem !important;
    line-height: 3.0rem !important;
  }
  h3 {
    font-size: 1.4rem !important;
    line-height: 2rem !important;
    margin-bottom: 20px !important;
  }
  .services p {
    font-size: 0.8rem;
  }
  .hero-btns {
    margin-bottom: 120px;
  }
}
@media (max-width: 991px) {
  .navbar {
    padding: 15px 0 0 0;
  }
  .hero img {
    display: none !important;
  }
  .hero {
    text-align: center !important;
  }
  .hero h1 {
    margin-top: 80px !important;
  }
  .navbar-collapse {
    text-align: center;
    margin-top: 50px;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 2rem;
  }
  .contact-btn {
    display: none;
  }
  .hero-btns {
    margin-bottom: 70px;
  }
  .service-txt {
    text-align: center;
  }
  .about img {
    margin-bottom: 70px;
  }
  .about {
    text-align: center;
  }
  .portfolio {
    text-align: center;
  }
  .blog {
    text-align: center;
  }
  .blog-box {
    margin: 0 auto;
    margin-bottom: 25px;
  }
  .contact {
    text-align: center;
  }

}
@media (max-width: 768px) {
  .hero h1 {
    font-size: 3.5rem !important;
  }
  .service-box {
    padding: 20px 25px;
    margin: 0px;
  }
  .contact-nav a {
    font-size: 1rem;
    padding: 20px 5px;
  }
}

@media (max-width: 575px) {
  .service-box {
    padding: 20px 25px;
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  footer h3{
    font-size: 2.5rem;
  }
}
@media (max-width: 454px) {
  .hero h1 {
    font-size: 3.0rem !important;
    margin-top: 50px !important;
  }
  .hero p {
    font-size: 0.8rem !important;
  }
  .hero-btns a:first-child {
    font-size: 0.8rem;
    display: block;
    width: 70%;
    margin: 0 auto;
  }
  .hero-btns a:last-child {
    font-size: 0.8rem;
    display: block;
    width: 70%;
    margin: 0 auto;
  }
  footer h3{
    line-height: 2.6rem;
  }
  .contact-nav li {
    display: block;
  }
  .social li {
    display: block;
  }
}

